(function($){

    /**
     * initializeBlock
     *
     * Adds custom JavaScript to the block HTML.
     *
     * @date    15/4/19
     * @since   1.0.0
     *
     * @param   object $block The block jQuery element.
     * @param   object attributes The block attributes (only available when editing).
     * @return  void
     */
    
    var initializeBlock = function( $block ) {
        //$block.find('img').doSomething();
        console.log("BLOCK | Init | ", "ACF Hero Image Full")


        $("#acf-hero-image-full .arrow-down").click(function() {
            $('html, body').animate({
                scrollTop: $("#arrowlink").offset().top - 0
            }, 2000);
        });

        setTimeout(function () {
            // Do Something Here
            $("#acf-hero-image-full .arrow-down").addClass("bounce");
        }, 15000);


    }
    var initializeScroll = function( $block ) {
        //$block.find('img').doSomething();
        //console.log("BLOCK | Scroll | ", "Hero Image")
        var scroll = $(window).scrollTop();
        //var ele = $block.outerHeight() - 200;
        var offset = $("#arrowlink").offset().top - $(window).scrollTop();
        //console.log("BLOCK | Scroll | ", scroll, ele)
		if (offset < 200) {
			$('#acf-hero-image-full .arrow-down').addClass('stop');
		} else {
			$('#acf-hero-image-full .arrow-down').removeClass('stop');
        }
    }

    // Initialize each block on page load (front end).
    $(document).ready(function(){
        $('#acf-hero-image-full').each(function(){
            initializeBlock( $(this) );
        });
    });
    $(window).scroll(function() {
        $('#acf-hero-image-full').each(function(){
            initializeScroll( $(this) );
        });
    });
    // Initialize dynamic block preview (editor).
    if( window.acf ) {
        //console.log("Window ACF")
        window.acf.addAction( 'render_block_preview', initializeBlock );
    }

})(jQuery);